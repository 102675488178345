import types from "../types";

// Clear state
export const handleClearStateAction = () => {
  let action = {
    type: types.CLEAR_STATE,
  };
  return action;
};

// Action to handle navigatin route state
export const handleNavigationStateAction = (index, isNested) => {
  let action = {
    type: types.HANDLE_SIDEBAR_NAVIGATION_STATE,
    index,
    isNested,
  };

  return action;
};

// Action to handle
export const handleSidebarDrawerToggleStateAction = (toggler) => {
  // cl('inside action', toggler)
  let action = {
    type: types.HANDLE_SIDEBAR_DRAWER_TOGGLE_STATE,
    toggler,
  };

  return action;
};

// Authentication actions

export const loginInitiate = (payload, history) => ({
  type: types.API_LOGIN_INITIATE,
  payload,
  history,
});

export const logoutInitiate = (history) => ({
  type: types.API_LOGOUT_INITIATE,
  history,
});

export const forgotPasswordInitiate = (payload, history) => ({
  type: types.API_FORGOT_PASSWORD_INITIATE,
  payload,
  history,
});

export const changePasswordInitiate = (payload, history) => ({
  type: types.API_CHANGE_PASSWORD_INITIATE,
  payload,
  history,
});

export const resetPasswordLinkInitiate = (payload) => ({
  type: types.API_RESET_PASSWORD_LINK_INITIATE,
  payload,
});

export const resetPasswordInitiate = (payload, history) => ({
  type: types.API_RESET_PASSWORD_INITIATE,
  payload,
  history,
});

export const userListInitiate = (payload) => ({
  type: types.API_USER_LIST_INITIATE,
  payload,
});

export const userBlockInitiate = (payload) => ({
  type: types.API_USER_BLOCK_INITIATE,
  payload,
});

export const userDetailInitiate = (payload) => ({
  type: types.API_USER_DETAIL_INITIATE,
  payload,
});

export const userDetailUpdateInitiate = (payload, history) => ({
  type: types.API_USER_DETAIL_UPDATE_INITIATE,
  payload,
  history,
});

export const dashboardInitiate = () => ({ type: types.API_DASHBOARD_INITIATE });

export const categoryListInitiate = (payload) => ({
  type: types.API_CATEGORY_LIST_INITIATE,
  payload,
});

export const addCategoryInitiate = (payload, history) => ({
  type: types.API_ADD_CATEGORY_INITIATE,
  payload,
  history,
});

export const editCategoryInitiate = (payload, history) => ({
  type: types.API_EDIT_CATEGORY_INITIATE,
  payload,
  history,
});

export const deleteCategoryInitiate = (payload) => ({
  type: types.API_DELETE_CATEGORY_INITIATE,
  payload,
});

export const needHelpListInitiate = (payload) => ({
  type: types.API_NEED_HELP_LIST_INITIATE,
  payload,
});

export const quoteListInitiate = (payload) => ({
  type: types.API_QUOTE_LIST_INITIATE,
  payload,
});

export const addQuoteInitiate = (payload, history) => ({
  type: types.API_ADD_QUOTE_INITIATE,
  payload,
  history,
});

export const editQuoteInitiate = (payload, history) => ({
  type: types.API_EDIT_QUOTE_INITIATE,
  payload,
  history,
});

export const deleteQuoteInitiate = (payload) => ({
  type: types.API_DELETE_QUOTE_INITIATE,
  payload,
});
export const chatAction = (payload) => ({ type: types.CHAT_LOAD, payload });

export const AddChatLocally = (payload) => ({
  type: types.CHAT_ADD_LOCALLY,
  payload,
});
