import React, { useState, useEffect, useRef } from "react";
import moment from "moment";

// Navigation
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
// Styles
import "antd/dist/antd.css";
import "./dashboard.scss";
// Third party Components
import * as Yup from "yup";
import { Formik } from "formik";
import clsx from "clsx";

// antd
import { Breadcrumb, Input, Table, Space } from "antd";
import { Card, Container, Row, Col, Table as BSTable } from "react-bootstrap";
// Mui Components
import { makeStyles } from "@material-ui/core/styles";
import {
  Drawer,
  Paper,
  Grid,
  CssBaseline,
  List,
  Box,
  Select,
  Typography,
  Divider,
  ListItem,
  useTheme,
  Collapse,
  Breadcrumbs,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
// Custom components
import DashboardLayout from "../../layouts/dashboardLayout";
import { InputField, Navbar, AppBar, Button } from "../../customComponents";
// fontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
// Constants
import { AppImages } from "../../themes/appImages";
import { Colors } from "../../themes/colors";
import { ValidationConstants, appConstants } from "../../themes/constants";

// utils methods
import cl from "../../utils/cl";
import { useStyles } from "../../styles/styles";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  handleNavigationStateAction,
  userDetailInitiate,
  userDetailUpdateInitiate,
} from "../../store/actions";
// Global constants

export const EditUserDetails = () => {
  // Hooks declarations
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const tabItemRef = useRef(null);
  const dispatch = useDispatch();
  const { appState } = useSelector((state) => state);
  const userDetail = useSelector((state) => state.userManagement.userDetail);

  // Global state initialization
  // const {sidebarRouteIndex,sidebarNestedRouteIndex} = appState

  // local state initialization

  //Images destructuring

  //Constants
  const [values, setValues] = useState({
    name: "",
    gender: "",
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Please enter name.")
      .min(2, "name must be 2 characters long.")
      .max(15, "Name should not be longer than 15 characters long."),
    // email: Yup.string(ValidationConstants.empty.emptyEmail).email(ValidationConstants.invalid.email.invalidEmail).max(255).required(ValidationConstants.empty.emptyEmail),
    gender: Yup.string().required("Please enter Gender."),
  });

  // Lifecycle Hooks
  useEffect(() => {
    document.title = appConstants.title;
    dispatch(userDetailInitiate({ id: location.state }));
  }, []);

  useEffect(() => {
    setValues({
      name: userDetail.name ?? "",
      gender: userDetail.gender ?? "",
    });
  }, [userDetail]);

  // Consoles

  //Callback methods

  return (
    <DashboardLayout>
      <AppBar
        breadcrumbs={[
          { route: "/users", name: "User Management" },
          { route: "/edit-user", name: "Edit User Details" },
        ]}
      />
      <Navbar title="Edit User Details" />
      <Card className={classes.card}>
        {/* <Box m={3} /> */}
        <Grid container>
          <Grid smUp={12} className={classes.tableContainerRow}>
            <Formik
              enableReinitialize={true}
              initialValues={values}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                dispatch(
                  userDetailUpdateInitiate(
                    { ...values, userId: location.state },
                    history
                  )
                );
              }}
            >
              {({
                values,
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <BSTable striped bordered>
                    <tbody>
                      <tr>
                        <td className={classes.rowKey}>Name</td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="Name"
                              name="name"
                              error={Boolean(touched.name && errors.name)}
                              helperText={touched.name && errors.name}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              type="text"
                              value={values.name}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>Email Address</td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              error={Boolean(touched.email && errors.email)}
                              helperText={touched.email && errors.email}
                              placeholder="Email Address"
                              name="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="email"
                              value={userDetail?.email ?? "N/A"}
                              disabled={true}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>Date Of Birth</td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <InputField
                              placeholder="dob"
                              name="dob"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              // type="number"
                              value={
                                userDetail?.dob
                                  ? moment(userDetail?.dob).format("DD-MM-YYYY")
                                  : "N/A"
                              }
                              disabled={true}
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}>Gender</td>
                        <td className={classes.rowValue}>
                          <div className={classes.inputWrapper}>
                            <Select
                              native
                              className={classes.selectInput}
                              value={values.gender}
                              onChange={handleChange}
                              inputProps={{
                                name: "gender",
                                id: "gender",
                              }}
                              IconComponent={() => (
                                <FontAwesomeIcon
                                  icon={faChevronDown}
                                  className={classes.dropdownIcon}
                                />
                              )}
                            >
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Wish not to disclose">
                                Wish not to disclose
                              </option>
                            </Select>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className={classes.rowKey}></td>
                        <td className={classes.rowValue}>
                          <button
                            type="submit"
                            className={clsx([
                              classes.authButton,
                              classes.filledButton,
                            ])}
                          >
                            Update
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </BSTable>
                </form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Card>
    </DashboardLayout>
  );
};
