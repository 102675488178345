import React, { useState, useEffect, useRef } from 'react';
// Navigation
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
// Styles
import 'antd/dist/antd.css'
import './dashboard.scss'
// antd
import { Breadcrumb, Input, Table, Space } from 'antd';
import { Card, Container, Row, Col, Table as BSTable, } from 'react-bootstrap';
// Mui Components
import { makeStyles } from '@material-ui/core/styles';
import { Drawer, Paper, Grid, CssBaseline, List, Box, Typography, Divider, ListItem, useTheme, Collapse, Breadcrumbs, ListItemText, ListItemIcon } from '@material-ui/core';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { AppBar, InputField, Navbar, Button, Dropdown } from '../../customComponents'
// Constants
import { AppImages } from '../../themes/appImages'
import { Colors } from '../../themes/colors'
import { appConstants } from '../../themes/constants'
// utils methods
import cl from '../../utils/cl'
import { useStyles } from '../../styles/styles'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { handleNavigationStateAction, userDetailInitiate } from '../../store/actions'


export const UserDetails = () => {
    // Hooks declarations
    const classes = useStyles();
    const dispatch = useDispatch()
    const location=useLocation()
    const userDetail = useSelector(state => state.userManagement.userDetail)

    // Lifecycle Hooks
    useEffect(() => {
        document.title = appConstants.title
        dispatch(userDetailInitiate({id:location.state}))
    }, [])

    return (
        <DashboardLayout>
            <AppBar breadcrumbs={[{ route: '/users', name: "User Management" }, { route: "/view-user-details", name: "User Details" }]} />
            <Navbar title="User Details" />
            <Card className={classes.card}>
                {/* <Box m={3} /> */}
                <Grid container>
                    <Grid smUp={12} className={classes.tableContainerRow}>
                        <BSTable striped bordered>
                            <tbody>
                                <tr>
                                    <td className={classes.rowKey}>
                                        Name
                                    </td>
                                    <td className={classes.rowValue}>
                                        <div className={classes.inputWrapper}>
                                            <InputField
                                                placeholder="User Name"
                                                name="username"
                                                // onChange={handleChange}
                                                type="username"
                                                value={userDetail?.name}
                                                disabled={true}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={classes.rowKey}>Email Address</td>
                                    <td className={classes.rowValue}>
                                        <div className={classes.inputWrapper}>
                                            <InputField
                                                placeholder="Email Address"
                                                name="email"
                                                // onChange={handleChange}
                                                type="email"
                                                value={userDetail?.email}
                                                disabled={true}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={classes.rowKey}>Gender</td>
                                    <td className={classes.rowValue}>
                                        <div className={classes.inputWrapper}>
                                            <InputField
                                                placeholder="Gender"
                                                name="gender"
                                                // onChange={handleChange}
                                                type="text"
                                                value={userDetail?.gender}
                                                disabled={true}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={classes.rowKey}>Date Of Birth</td>
                                    <td className={classes.rowValue}>
                                        <div className={classes.inputWrapper}>
                                            <InputField
                                                placeholder="Date Of Birth"
                                                name="dob"
                                                // onChange={handleChange}
                                                // type="email"
                                                value={userDetail?.dob?moment(userDetail?.dob).format("DD-MM-YYYY"):"N/A"}
                                                disabled={true}
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className={classes.rowKey}>Report Count</td>
                                    <td className={classes.rowValue}>
                                        <div className={classes.inputWrapper}>
                                            <InputField
                                                placeholder="Report Count"
                                                name="reportCount"
                                                // onChange={handleChange}
                                                type="number"
                                                value="0"
                                                disabled={true}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </BSTable>
                    </Grid>
                </Grid>
            </Card>
        </DashboardLayout>
    );
}
