const types = {
    CLEAR_STATE: "CLEAR_STATE",
    HANDLE_SIDEBAR_NAVIGATION_STATE: "HANDLE_SIDEBAR_NAVIGATION_STATE",
    HANDLE_SIDEBAR_DRAWER_TOGGLE_STATE: "HANDLE_SIDEBAR_DRAWER_TOGGLE_STATE",

    // Authentication

    API_LOGIN_INITIATE: "API_LOGIN_INITIATE",
    API_LOGIN_SUCCESS: "API_LOGIN_SUCCESS",

    API_LOGOUT_INITIATE: "API_LOGOUT_INITIATE",
    API_LOGOUT_SUCCESS: "API_LOGOUT_SUCCESS",

    API_FORGOT_PASSWORD_INITIATE: "API_FORGOT_PASSWORD_INITIATE",
    API_FORGOT_PASSWORD_SUCCESS: "API_FORGOT_PASSWORD_SUCCESS",

    API_CHANGE_PASSWORD_INITIATE: "API_CHANGE_PASSWORD_INITIATE",
    API_CHANGE_PASSWORD_SUCCESS: "API_CHANGE_PASSWORD_SUCCESS",

    API_RESET_PASSWORD_LINK_INITIATE: "API_RESET_PASSWORD_LINK_INITIATE",
    API_RESET_PASSWORD_LINK_SUCCESS: "API_RESET_PASSWORD_LINK_SUCCESS",

    API_RESET_PASSWORD_INITIATE: "API_RESET_PASSWORD_INITIATE",
    API_RESET_PASSWORD_SUCCESS: "API_RESET_PASSWORD_SUCCESS",

    API_USER_LIST_INITIATE: "API_USER_LIST_INITIATE",
    API_USER_LIST_SUCCESS: "API_USER_LIST_SUCCESS",

    API_USER_BLOCK_INITIATE: "API_USER_BLOCK_INITIATE",
    API_USER_BLOCK_SUCCESS: "API_USER_BLOCK_SUCCESS",

    API_USER_DETAIL_INITIATE: "API_USER_DETAIL_INITIATE",
    API_USER_DETAIL_SUCCESS: "API_USER_DETAIL_SUCCESS",

    API_USER_DETAIL_UPDATE_INITIATE: "API_USER_DETAIL_UPDATE_INITIATE",
    API_USER_DETAIL_UPDATE_SUCCESS: "API_USER_DETAIL_UPDATE_SUCCESS",

    API_DASHBOARD_INITIATE: "API_DASHBOARD_INITIATE",
    API_DASHBOARD_SUCCESS: "API_DASHBOARD_SUCCESS",
    API_DASHBOARD_FAILED: "API_DASHBOARD_FAILED",

    API_USER_MANAGEMENT_FAILED: "API_USER_MANAGEMENT_FAILED",
    API_AUTHENTICATION_FAILED: "API_AUTHENTICATION_FAILED",

    // Category Management

    API_CATEGORY_LIST_INITIATE: "API_CATEGORY_LIST_INITIATE",
    API_CATEGORY_LIST_SUCCESS: "API_CATEGORY_LIST_SUCCESS",

    API_ADD_CATEGORY_INITIATE: "API_ADD_CATEGORY_INITIATE",
    API_ADD_CATEGORY_SUCCESS: "API_ADD_CATEGORY_SUCCESS",

    API_EDIT_CATEGORY_INITIATE: "API_EDIT_CATEGORY_INITIATE",
    API_EDIT_CATEGORY_SUCCESS: "API_EDIT_CATEGORY_SUCCESS",

    API_DELETE_CATEGORY_INITIATE: "API_DELETE_CATEGORY_INITIATE",
    API_DELETE_CATEGORY_SUCCESS: "API_DELETE_CATEGORY_SUCCESS",

    API_CATEGORY_MANAGEMENT_FAILED: "API_CATEGORY_MANAGEMENT_FAILED",
    API_CATEGORY_MANAGEMENT_SUCCESS: "API_CATEGORY_MANAGEMENT_SUCCESS",

    // Need help

    API_NEED_HELP_LIST_INITIATE: "API_NEED_HELP_LIST_INITIATE",
    API_NEED_HELP_LIST_SUCCESS: "API_NEED_HELP_LIST_SUCCESS",

    API_NEED_HELP_MANAGEMENT_FAILED: "API_NEED_HELP_MANAGEMENT_FAILED",

    // Quote management

    API_QUOTE_LIST_INITIATE: "API_QUOTE_LIST_INITIATE",
    API_QUOTE_LIST_SUCCESS: "API_QUOTE_LIST_SUCCESS",

    API_ADD_QUOTE_INITIATE: "API_ADD_QUOTE_INITIATE",
    API_ADD_QUOTE_SUCCESS: "API_ADD_QUOTE_SUCCESS",

    API_EDIT_QUOTE_INITIATE: "API_EDIT_QUOTE_INITIATE",
    API_EDIT_QUOTE_SUCCESS: "API_EDIT_QUOTE_SUCCESS",

    API_DELETE_QUOTE_INITIATE: "API_DELETE_QUOTE_INITIATE",
    API_DELETE_QUOTE_SUCCESS: "API_DELETE_QUOTE_SUCCESS",

    API_QUOTE_MANAGEMENT_FAILED: "API_QUOTE_MANAGEMENT_FAILED",


    // CHAT 
    CHAT_LOAD:"CHAT_LOAD",
    CHAT_LOAD_SUCCESS:"CHAT_LOAD_SUCCESS",
    CHAT_LOAD_FAIL:"CHAT_LOAD_FAIL",

    CHAT_ADD_LOCALLY:"CHAT_ADD_LOCALLY",
}

export default types