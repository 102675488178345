import React from 'react'
import {
    InputAdornment,
    TextField,
} from '@mui/material'
import clsx from 'clsx'
import { useStyles } from './styles'

const InputField = (props) => {

    const classes = useStyles()

    const {
        error,
        helperText,
        placeholder,
        name,
        onBlur,
        onChange,
        type,
        value,
        className,
        autoComplete,
        disabled,
        my,
        textArea,
        onKeyDown,
        endAdornment
    } = props
    return (
        <TextField
            placeholder={placeholder}
            error={error}
            noValidate
            helperText={helperText}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            type={type}
            value={value}
            fullWidth
            className={clsx([classes.input, my && classes.my])}
            multiline={textArea && true}
            rows={textArea && 7}
            autoComplete={autoComplete ? autoComplete : ""}
            disabled={disabled}
            onKeyDown={onKeyDown}
        />
    )
}


export default InputField