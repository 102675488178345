import React, { useState, useEffect, useRef } from 'react';
// Navigation
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import './dashboard.scss'
// antd
import { Input, Table, Button as AntButton, Pagination, Modal } from 'antd';
import { Card, Container, Row, Col } from 'react-bootstrap';

// fontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShare } from '@fortawesome/free-solid-svg-icons'
// Mui Components
import { Grid, useTheme, useMediaQuery, Box } from '@material-ui/core';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { AppBar, Button, PaginationBar, Navbar, NavbarSearch, ConfirmationModal } from '../../customComponents'
// Constants
import { AppImages } from '../../themes/appImages'
import { Colors } from '../../themes/colors'
import { appConstants } from '../../themes/constants'
// utils methods
import cl from '../../utils/cl'
import { useStyles } from '../../styles/styles'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { handleNavigationStateAction, quoteListInitiate, deleteQuoteInitiate } from '../../store/actions'

export const QuotesListing = () => {
    const styleProps = { searchable: true }
    // Hooks declarations
    const classes = useStyles(styleProps);
    const theme = useTheme();
    const history = useHistory();

    const [currentPage, setCurrentPage] = useState(1)
    const [payload, setPayload] = useState({ offset: 0, limit: 10, sort: "", order: 0, search: "" })
    const quoteList = useSelector(x => x.quoteManagement?.quoteList)
    const pagination = useSelector(x => x.quoteManagement.pagination)
    const dispatch = useDispatch()
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const [showModal, setShowModal] = useState(false)
    const cancelModel = () => setShowModal(false)
    const [selectedQuote, setSelectedQuote] = useState(null)


    const handleChange = (pagination, filters, sorter) => {

        const tempPayload = { ...payload, sort: sorter?.columnKey, order: sorter.order === "ascend" ? 1 : sorter.order === "descend" ? -1 : null, offset: currentPage === 1 ? 0 : (currentPage - 1) * 10 }
        setPayload(tempPayload)
        dispatch(quoteListInitiate(tempPayload))
    }

    const handleSearch = (e) => {

        if (!e.target.value.startsWith(" ")) {

            const tempPayload = { ...payload, search: e.target.value.trimLeft() }
            setPayload(tempPayload)
            dispatch(quoteListInitiate(tempPayload))
        }
        else e.target.value = ""
    }

    const handlePagination = (pagination) => {


        setCurrentPage(pagination)
        const tempPayload = { ...payload, offset: pagination == 1 ? 0 : (pagination - 1) * 10 }
        setPayload(tempPayload)
        dispatch(quoteListInitiate(tempPayload))
        window.scrollTo(0, 0)

    }

    //lifecycle hooks
    useEffect(() => {
        document.title = appConstants.title;
        dispatch(quoteListInitiate(payload))
    }, [])

    const columns = [
        {
            title: 'Sr. No.',
            dataIndex: 'sr',
            key: 'sr',
            ellipsis: false,
            render: (key, data, index) => (<>{payload.offset + index + 1}</>),
        },
        {
            title: 'Quote Name',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            sorter: true
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: "300px",
            align: 'center',
            render: (key, data) => {
                return (
                    <Grid className={classes.buttons}>

                        <button className="table-action-button" onClick={() => history.push({
                            pathname: '/edit-quotes',
                            state: data
                        })}>
                            Edit
                        </button>

                        <button className="table-action-button" onClick={() => {
                            setSelectedQuote(data._id)
                            setShowModal(true)
                        }}>
                            Delete
                        </button>

                    </Grid>
                )
            }
        },
    ];

    return (
        <DashboardLayout>
            <AppBar breadcrumbs={[{ route: '/Quotes-Mangement', name: "Quotes Mangement" }]} />
            <button className="add-category-button" onClick={() => history.push('/add-quotes')}>Add Quotes</button>

            <Navbar title="Quotes Management" searchable onChange={handleSearch} value={payload.search} />
            <Card className={classes.card}>
                {matches &&
                    <Box className={classes.searchBox}>
                        <NavbarSearch />
                    </Box>
                }
                <Grid container>
                    <Grid smUp={12} className={classes.tableContainerRow}>
                        <Modal
                            visible={showModal}
                            title="Delete Quote"
                            closable={false}
                            onCancel={cancelModel}
                            centered={true}
                            footer={[
                                <div className="delete-category-modal-footer">
                                    <button onClick={() => {
                                        setShowModal(false)
                                        dispatch(deleteQuoteInitiate({ ...payload, id: selectedQuote }))
                                    }}>Delete</button>
                                    <button onClick={cancelModel}>Cancel</button>
                                </div>
                            ]}

                            className="delete-category-modal"
                        >
                            <div className="delete-category-modal-text">Are you sure to delete this quote?</div>
                        </Modal>
                        <Table className={classes.tableWrapper} scroll bordered columns={columns} dataSource={quoteList} onChange={handleChange} />
                        <Pagination current={currentPage} total={pagination?.totalCount ? pagination.totalCount : 10} className="table-pagination" onChange={handlePagination} />

                    </Grid>
                </Grid>
            </Card>
        </DashboardLayout>
    );
}
