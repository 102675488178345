import React, { useState, useEffect, useRef } from 'react';
// Navigation
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import './dashboard.scss'
// antd
import { Input, Table, Button as AntButton, Pagination } from 'antd';
import { Card, Container, Row, Col } from 'react-bootstrap';

// fontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShare } from '@fortawesome/free-solid-svg-icons'
// Mui Components
import { Grid, useTheme, useMediaQuery, Box } from '@material-ui/core';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { AppBar, Button, PaginationBar, Navbar, NavbarSearch, ConfirmationModal } from '../../customComponents'
// Constants
import { AppImages } from '../../themes/appImages'
import { Colors } from '../../themes/colors'
import { appConstants } from '../../themes/constants'
// utils methods
import cl from '../../utils/cl'
import { useStyles } from '../../styles/styles'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { handleNavigationStateAction } from '../../store/actions'

export const StoreListing = () => {
    const styleProps = { searchable: true }
    // Hooks declarations
    const classes = useStyles(styleProps);
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation()
    const tabItemRef = useRef(null)
    const dispatch = useDispatch()
    const { appState } = useSelector(state => state)
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    // Global state initialization
    // const {sidebarRouteIndex,sidebarNestedRouteIndex} = appState

    // local state initialization
    const [sortedInfo, setSortedInfo] = useState(null)
    const [filteredInfo, setFilteredInfo] = useState(null)
    const [confirm, setConfirm] = useState(false)
    //Images destructuring

    //Constants


    //lifecycle hooks
    useEffect(() => {
        document.title = appConstants.title;
        // dispatch(handleNavigationStateAction(1, true))
    }, [])

    //Callback methods
    const handleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters)
        setSortedInfo(sorter)
    }

    const clearFilters = () => {
        setFilteredInfo({})
    };

    const clearAll = () => {
        setFilteredInfo({})
        setSortedInfo({})
    };

    const setAgeSort = () => {
        setSortedInfo({
            order: 'descend',
            columnKey: 'age',
        })
    };


    const columns = [
        {
            title: 'Sr. No.',
            dataIndex: 'sr',
            key: 'sr',
            // filters: [
            //   { text: 'Joe', value: 'Joe' },
            //   { text: 'Jim', value: 'Jim' },
            // ],
            // filteredValue: filteredInfo.name || null,
            // onFilter: (value, record) => record.name.includes(value),
            // sorter: (a, b) => a.name.length - b.name.length,
            // sortOrder: sortedInfo.columnKey === 'number' && sortedInfo.order,
            ellipsis: false,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            // sorter: (a, b) => a.age - b.age,
            // sortOrder: sortedInfo.columnKey === 'age' && sortedInfo.order,
            ellipsis: false,
        }, {
            title: 'Sub-title ',
            dataIndex: 'subTitle',
            key: 'sub-title',
            // sorter: (a, b) => a.age - b.age,
            // sortOrder: sortedInfo.columnKey === 'age' && sortedInfo.order,
            ellipsis: false,
        }, {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            // sorter: (a, b) => a.age - b.age,
            // sortOrder: sortedInfo.columnKey === 'age' && sortedInfo.order,
            ellipsis: false,
        }, {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            // sorter: (a, b) => a.age - b.age,
            // sortOrder: sortedInfo.columnKey === 'age' && sortedInfo.order,
            ellipsis: false,
        }
        , {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            // sorter: (a, b) => a.age - b.age,
            // sortOrder: sortedInfo.columnKey === 'age' && sortedInfo.order,
            ellipsis: false,
        }

    ];

    const data = [
        {
            key: '1',
            sr: 1,
            title: "category 1",
            subTitle: "sub-title",
            price: "100",
            type: "type",
            category: "category"

        },

    ];

    return (
        <DashboardLayout>
            <AppBar breadcrumbs={[{ route: '/Store-Listing', name: "Store Mangement" }]} />
            <Navbar title="Store Mangement" searchable />
            <Card className={classes.card}>
                {matches &&
                    <Box className={classes.searchBox}>
                        <NavbarSearch />
                    </Box>
                }
                <Grid container>
                    <Grid smUp={12} className={classes.tableContainerRow}>
                        <Table className={classes.tableWrapper} scroll bordered columns={columns} dataSource={data} />
                        <Pagination current={1} total={10} className="table-pagination" />

                    </Grid>
                </Grid>
            </Card>
        </DashboardLayout>
    );
}
