//React router navigation
import { Dashboard } from "../pages/dashboard/dashboard";
import { UserManagement } from "../pages/dashboard/userManagement";
import { UserDetails } from "../pages/dashboard/userDetails";
import { EditUserDetails } from "../pages/dashboard/editUserDetails";
import { EditCategory } from "../pages/dashboard/editCategory";
import { Redirect } from "react-router-dom";
import { CategoryListing } from "../pages/dashboard/categoryListing";
import { QuotesListing } from "../pages/dashboard/quotes";
import { ChangePassword } from "../pages/auth/changePassword";
import { AddCategory } from "../pages/dashboard/addCategory";
import { AddQuotes } from "../pages/dashboard/addQuotes";
import { StoreListing } from "../pages/dashboard/storeListing";
import { EditQuotes } from "../pages/dashboard/editQuotes";
import { NeedHelp } from "../pages/dashboard/needHelp";
import ChatScreen from "../pages/dashboard/chat";

const routes = [
  {
    path: "/",
    component: () => <Redirect to="/dashboard" />,
    exact: true,
  },
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/users",
    component: UserManagement,
  },

  {
    // path:"/view-user-details/:id",
    path: "/view-user-details",
    component: UserDetails,
  },
  {
    // path:"/view-saved-locations/:id",
    path: "/view-saved-locations",
    component: UserDetails,
  },
  {
    // path:"/edit-user/:id",
    path: "/edit-user",
    component: EditUserDetails,
  },
  {
    path: "/Category-Mangement",
    component: CategoryListing,
  },
  {
    // path:"/edit-terminal/:id",
    path: "/add-category",
    component: AddCategory,
  },
  {
    // path:"/edit-terminal/:id",
    path: "/edit-category",
    component: EditCategory,
  },
  {
    path: "/Change-Password",
    component: ChangePassword,
  },
  {
    path: "/store-Listing",
    component: StoreListing,
  },
  {
    path: "/Quotes-Mangement",
    component: QuotesListing,
  },
  {
    // path:"/edit-terminal/:id",
    path: "/add-quotes",
    component: AddQuotes,
  },
  {
    // path:"/edit-terminal/:id",
    path: "/edit-quotes",
    component: EditQuotes,
  },
  {
    // path:"/edit-terminal/:id",
    path: "/need-help",
    component: NeedHelp,
  },
  {
    // path:"/edit-terminal/:id",
    path: "/chat-screen",
    component: ChatScreen,
  },
];

export default routes;
