import axios from "axios";

// server url
const baseURL = "https://api.goalsyapp.com/api/v1"


const APIKit = axios.create({ baseURL, timeout: 60000000, });

APIKit.interceptors.request.use(async (config) => {

    if (localStorage.getItem("token")) config.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;

    return config;
});

export default APIKit;