import { takeLatest, call, put } from "redux-saga/effects";
import types from "../types/index";
import api from "../../apiKit/api";
import { toast } from "react-toastify";
import { appConstants } from "../../themes/constants";

function* needHelpListSaga({ payload }) {
  try {
    let response = yield call(api.needHelpListApi, payload);

    if (response?.status >= 200 && response?.status < 400)
      yield put({
        type: types.API_NEED_HELP_LIST_SUCCESS,
        result: response.data,
      });
    else {
      toast.error(response?.message, { toastId: "err" });
      yield put({ type: types.API_NEED_HELP_MANAGEMENT_FAILED });
    }
  } catch (error) {
    toast.error(appConstants.axiosConstants.messageStatus500, {
      toastId: "err",
    });
    yield put({ type: types.API_NEED_HELP_MANAGEMENT_FAILED });
  }
}

function* chatLoad({ payload }) {
  try {
    console.log(payload);
    let response = yield call(api.chatLoad, payload);
    if (response?.status >= 200 && response?.status < 400)
      yield put({
        type: types.CHAT_LOAD_SUCCESS,
        result: { ...response.data, ticketId: payload.ticketId },
      });
    else {
      toast.error(response?.message, { toastId: "err" });
      yield put({ type: types.CHAT_LOAD_FAIL });
    }
  } catch (error) {
    console.log(error);
    toast.error(appConstants.axiosConstants.messageStatus500, {
      toastId: "err",
    });
    yield put({ type: types.CHAT_LOAD_FAIL });
  }
}

export default function* rootNeedHelpManagementSaga() {
  yield takeLatest(types.CHAT_LOAD, chatLoad);
  yield takeLatest(types.API_NEED_HELP_LIST_INITIATE, needHelpListSaga);
}
