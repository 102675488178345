import { takeLatest, call, put } from "redux-saga/effects";
import types from '../types/index'
import api from "../../apiKit/api";
import { toast } from "react-toastify";
import { appConstants } from '../../themes/constants'

function* categoryListSaga({ payload }) {

    try {

        let response = yield call(api.categoryListApi, payload);

        if (response?.status >= 200 && response?.status < 400) yield put({ type: types.API_CATEGORY_LIST_SUCCESS, result: response.data });

        else {

            toast.error(response?.message, { toastId: "err" });
            yield put({ type: types.API_CATEGORY_MANAGEMENT_FAILED, });
        }
    }
    catch (error) {

        toast.error(appConstants.axiosConstants.messageStatus500, { toastId: "err" });
        yield put({ type: types.API_CATEGORY_MANAGEMENT_FAILED, });
    }
}

function* addCategorySaga({ payload, history }) {

    try {

        let response = yield call(api.addCategoryApi, payload);

        if (response?.status >= 200 && response?.status < 400) {

            toast.success("Category has been added successfully.")
            yield put({ type: types.API_CATEGORY_MANAGEMENT_SUCCESS });
            history.push('/Category-Mangement')
        }

        else {

            toast.error(response?.message, { toastId: "err" });
            yield put({ type: types.API_CATEGORY_MANAGEMENT_FAILED, });
        }
    }
    catch (error) {

        toast.error(appConstants.axiosConstants.messageStatus500, { toastId: "err" });
        yield put({ type: types.API_CATEGORY_MANAGEMENT_FAILED, });
    }
}

function* editCategorySaga({ payload, history }) {

    try {

        let response = yield call(api.editCategoryApi, payload);

        if (response?.status >= 200 && response?.status < 400) {

            toast.success(response?.message)
            yield put({ type: types.API_CATEGORY_MANAGEMENT_SUCCESS });
            history.push('/Category-Mangement')
        }

        else {

            toast.error(response?.message, { toastId: "err" });
            yield put({ type: types.API_CATEGORY_MANAGEMENT_FAILED, });
        }
    }
    catch (error) {

        toast.error(appConstants.axiosConstants.messageStatus500, { toastId: "err" });
        yield put({ type: types.API_CATEGORY_MANAGEMENT_FAILED, });
    }
}

function* deleteCategorySaga({ payload }) {

    try {

        let response = yield call(api.deleteCategoryApi, payload);

        if (response?.status >= 200 && response?.status < 400) {

            toast.success(response?.message)
            yield put({ type: types.API_DELETE_CATEGORY_SUCCESS });
            yield put({ type: types.API_CATEGORY_LIST_INITIATE, payload })
        }

        else {

            toast.error(response?.message, { toastId: "err" });
            yield put({ type: types.API_CATEGORY_MANAGEMENT_FAILED, });
        }
    }
    catch (error) {

        toast.error(appConstants.axiosConstants.messageStatus500, { toastId: "err" });
        yield put({ type: types.API_CATEGORY_MANAGEMENT_FAILED, });
    }
}
export default function* rootCategoryManagementSaga() {
    yield takeLatest(types.API_CATEGORY_LIST_INITIATE, categoryListSaga);
    yield takeLatest(types.API_ADD_CATEGORY_INITIATE, addCategorySaga);
    yield takeLatest(types.API_EDIT_CATEGORY_INITIATE, editCategorySaga);
    yield takeLatest(types.API_DELETE_CATEGORY_INITIATE, deleteCategorySaga);

}
