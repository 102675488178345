import { all, fork } from "redux-saga/effects";
import rootAuthenticationSaga from "./authentication";
import rootUserManagementSaga from './userManagement'
import rootCategoryManagementSaga from './categoryManagement'
import rootNeedHelpManagementSaga from './needHelpManagement'
import rootQuoteManagementSaga from './quoteManagement'

export default function* rootSaga() {

    yield all([
        fork(rootAuthenticationSaga),
        fork(rootUserManagementSaga),
        fork(rootCategoryManagementSaga),
        fork(rootNeedHelpManagementSaga),
        fork(rootQuoteManagementSaga)
    ]);
}
