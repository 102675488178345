import React, { useState, useEffect, useRef } from 'react';
// Navigation
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom'
// Styles
import 'antd/dist/antd.css'
import './dashboard.scss'
// Third party Components
import * as Yup from 'yup';
import { Formik } from 'formik';
import clsx from 'clsx'
// antd
import { Breadcrumb, Input, Table, Space, Upload, Modal, message, Tooltip, } from 'antd';
import { Card, Container, Row, Col, Table as BSTable, } from 'react-bootstrap';
// fontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-regular-svg-icons'
// Mui Components

import { Drawer, Paper, Grid, CssBaseline, List, Box, Select, Typography, Divider, ListItem, useTheme, Collapse, Breadcrumbs, ListItemText, ListItemIcon } from '@material-ui/core';
// Custom components
import DashboardLayout from '../../layouts/dashboardLayout'
import { InputField, Navbar, AppBar, Button } from '../../customComponents'
// Constants
import { AppImages } from '../../themes/appImages'
import { Colors } from '../../themes/colors'
import { appConstants } from '../../themes/constants'
// utils methods
import cl from '../../utils/cl'
import { useStyles } from '../../styles/styles'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { handleNavigationStateAction, editCategoryInitiate } from '../../store/actions'
// Global constants


export const EditCategory = () => {
    // Hooks declarations
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation()
    const dispatch = useDispatch()

    //Constants
    const values = {
        name: location?.state?.name ?? ""
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Please enter category name.')
            .min(2, 'Category name must be 2 characters long.')
            .max(15, "Category name should not be longer than 15 characters long.")
    })

    // Lifecycle Hooks
    useEffect(() => {
        document.title = appConstants.title
    }, [])
    // Consoles 

    //Callback methods
    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }
    return (
        <DashboardLayout>
            <AppBar breadcrumbs={[{ route: '/Category-Mangement', name: "Category Management" }, { route: '/edit-category', name: "Edit Category" }]} />
            <Navbar title="Edit Category" />
            <Card className={classes.card}>
                <Grid container>
                    <Grid className={classes.tableContainerRow}>
                        <Formik
                            enableReinitialize={true}
                            initialValues={values}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {

                                dispatch(editCategoryInitiate({ categoryId: location.state._id, name: values.name }, history))
                            }}
                        >
                            {({
                                values,
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched,
                            }) => (
                                    <form onSubmit={handleSubmit}>
                                        <BSTable striped bordered>
                                            <tbody>
                                                <tr>
                                                    <td className={classes.rowKey}>
                                                        Category Name
                                                </td>
                                                    <td className={classes.rowValue}>
                                                        <div className={classes.inputWrapper}>
                                                            <InputField
                                                                placeholder="Category Name"
                                                                name="name"
                                                                error={Boolean(touched.name && errors.name)}
                                                                helperText={touched.name && errors.name}
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                type="text"
                                                                value={values.name}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className={classes.rowKey}></td>
                                                    <td className={classes.rowValue}>
                                                        <button
                                                            type="submit"
                                                            className={clsx([classes.authButton, classes.filledButton])}
                                                        >
                                                            Update
                                                    </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </BSTable>
                                    </form>
                                )}
                        </Formik>
                    </Grid>
                </Grid>
            </Card>
        </DashboardLayout>
    );
}
