import React, { useEffect } from "react";
import Setup from "./routes/setup";
import "react-toastify/dist/ReactToastify.css";

import "./App.scss";
import {
  ThemeProvider,
  StylesProvider,
  jssPreset,
} from "@material-ui/core/styles";
import { create } from "jss";
import extend from "jss-plugin-extend";
import { ToastContainer } from "react-toastify";

import theme from "./theme";
import { useHistory } from "react-router-dom";
import { getNavigate } from "./apiKit/method";
import { socket } from "./utils/socket";
import { useDispatch } from "react-redux";
import { AddChatLocally } from "./store/actions";
const jss = create({
  plugins: [...jssPreset().plugins, extend()],
});

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    getNavigate(history);
  }, []);

  const token = localStorage.getItem("token");

  const handleOnline = (e) => {
    if (!socket.connected) {
      socket.connect();
    }
  };

  useEffect(() => {
    if (!socket.connected) {
      socket.connect();
      setTimeout(() => {
        (async () => {
          token && socket.emit("login", token);
        })();
      }, 100);

      window.addEventListener("online", handleOnline);

      socket.on("newMessage", (e) => {
        dispatch(AddChatLocally({ ...e, messageByAdmin: false }));
      });
    }

    return () => {
      window.removeEventListener("online", handleOnline);
      socket.off("newMessage");
    };
  }, []);

  return (
    <>
      <ToastContainer />
      <StylesProvider jss={jss}>
        <ThemeProvider theme={theme}>
          <div className="App">
            <Setup />
          </div>
        </ThemeProvider>
      </StylesProvider>
    </>
  );
}

export default App;
