import types from "../types/index";

const initialState = {
  isLoading: false,
  helpList: [],
  pagination: {},
  chatPagination: {},
  chat: [],
  chatClose: false,
  currentChatID: null,
};

export const needHelpManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    // Need help list
    case types.API_NEED_HELP_LIST_INITIATE:
      return { ...state, isLoading: true };
    case types.API_NEED_HELP_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        helpList: action?.result?.data.data,
        pagination: action.result.data.paginationData,
      };
    case types.CHAT_LOAD:
      return { ...state, isLoading: true };
    case types.CHAT_LOAD_SUCCESS:
      console.log(action?.result);
      return {
        ...state,
        isLoading: false,
        chat: action?.result?.data.data,
        chatPagination: action.result.data.paginationData,
        chatClose: action.result.data.closed,
        currentChatID: action.result.ticketId,
      };

    //User management Failed Case
    case types.API_NEED_HELP_MANAGEMENT_FAILED:
      return { ...state, isLoading: false };

    case types.CHAT_ADD_LOCALLY:
      console.log('====================================');
      console.log(state.currentChatID ,action.payload);
      console.log('====================================');
      if (state.currentChatID === action.payload.ticketId) {
        const chat = {
          ...action.payload,
          messageByAdmin: action.payload.messageByAdmin ?? false,
          createdAt: new Date().toUTCString(),
        };
        return {
          ...state,
          chat: [...state.chat,chat],
        };
      }

      return state;

    //Default case
    default:
      return { ...state };
  }
};
