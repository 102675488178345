export const AppImages = {
    'truckBg': require('../assets/images/truckBg.png').default,
    'logo': require('../assets/images/logo.png').default,
    'dummyProfilePic': require('../assets/images/dummy_terminal.png').default,
    'pin': require('../assets/images/pin.png').default,
    'usersSvg': require('../assets/icons/images.svg').default,
    'terminalSvg':require('../assets/icons/delivery.svg').default,
    'rightArrowSvg':require('../assets/icons/rightArrow.svg').default,
    'time':require('../assets/icons/time.svg').default,
    'banner':require("../assets/images/Banner.png").default
}