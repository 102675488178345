import React, { useState, useEffect, useCallback, useRef } from "react";
import moment from "moment";
// Navigation
import { useLocation,useHistory } from "react-router-dom";
// Styles
import "antd/dist/antd.css";
import "./dashboard.scss";
import { Grid, IconButton } from "@material-ui/core";
// Custom components
import DashboardLayout from "../../layouts/dashboardLayout";
import { AppBar, InputField, Navbar } from "../../customComponents";
// Constants
import { appConstants } from "../../themes/constants";
// utils methods
import { useStyles } from "../../styles/styles";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { AddChatLocally, chatAction } from "../../store/actions";
import { Card } from "react-bootstrap";
import { Send } from "@material-ui/icons";
import { socket } from "../../utils/socket";
import { useLayoutEffect } from "react";
import { Input } from "antd";
import { toast } from "react-toastify";

const ChatScreen = () => {
  const styleProps = { searchable: true };
  // Hooks declarations
  const classes = useStyles(styleProps);
  const { state } = useLocation();
  const dispatch = useDispatch();
  // const pagination = useSelector((x) => x.needHelpManagement.pagination);

  const scrollRef = useRef(null);

  const [payload, setPayload] = useState({
    offset: 0,
    limit: 10000,
  });
  const [message, setMessage] = useState("");
  const [ticketClose, setTicketClose] = useState(false);
  const [sendMessage, setSendMessage] = useState(true);
  const { chat, chatClose } = useSelector((x) => x.needHelpManagementReducer);
  const history = useHistory()
  const messageObj = {
    ticketId: state.ticketId,
    userId: state.userId,
    message: "",
  };

  useLayoutEffect(() => {
    document.title = appConstants.title;
    payload.ticketId = state.ticketId;
    window.addEventListener("online", () => {
      dispatch(chatAction(payload));
    });

    dispatch(chatAction(payload));
  }, []);

  const scrollToBottom = () => {
    scrollRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  useEffect(() => {
    socket.on("error", (e) => {
      toast.error(e.message, { toastId: "err" });
      setTicketClose(true);
      history.goBack()
    });

    return () => {
      socket.off("error");
    };
  }, []);

  const onSend = useCallback((message) => {
    if (window.navigator.onLine && socket.connected) {
      if (message) {
        messageObj.message = message.trimEnd();
        socket.emit("message", messageObj, (e) => {
          dispatch(
            AddChatLocally({
              message: e.message,
              ticketId: state.ticketId,
              messageByAdmin: true,
            })
          );
        });
        setMessage("");
        setTimeout(() => {
          scrollToBottom();
        }, 10);
      }
    } else {
      toast.error("Please check you internet connection and try again.");
    }
  }, []);

  const RenderMessage = useCallback(() => {
    return chat.map((item, index) => {
      return (
        <div
          key={item.time}
          style={{
            display: "flex",
            justifyContent: !item.messageByAdmin ? "flex-start" : "flex-end",
            width: "100%",
          }}
        >
          <Card
            style={{
              width: "70%",
              minWidth: "100px",
              marginRight: 0,
              borderRadius: "15px",
              margin: "10px 0 0",
              padding: "10px",
              maxWidth: "400px",
              border: !item.messageByAdmin && "2px solid rgb(221 160 221)",
              background: item.messageByAdmin ? "#dda0dd61" : "#fff",
            }}
          >
            <div>
              <span style={{ whiteSpace: "pre-wrap", margin: 0, fontSize: 22 }}>
                {item.message}
              </span>
            </div>
            <span style={{ display: "flex", justifyContent: "flex-end" }}>
              {moment(item.time).format("hh:mm A")}
            </span>
          </Card>
        </div>
      );
    });
  }, [chat]);

  useLayoutEffect(() => {
    setTicketClose(chatClose);
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  }, [chat]);

  return (
    <DashboardLayout>
      <AppBar
        breadcrumbs={[
          { route: "/need-help", name: "Need Help" },
          { route: "/chat-screen", name: "Chat" },
        ]}
      />
      <Grid container className="mt-4">
        <Navbar title={`Ticket ID: ${state.id}`} />
        <Card
          className={classes.card}
          style={{
            height: `calc(100vh - 196px)`,
            overflowY: "hidden",
          }}
        >
          <Grid
            id={"chat_container"}
            container
            style={{ width: "100%", overflowY: "auto", height: "100%" }}
            className="chat"
          >
            <div
              id="box"
              ref={scrollRef}
              className={`${classes.tableContainerRow}`}
              style={{
                marginTop: 40,
                paddingBottom: 10,
                width: "100%",
              }}
            >
              {chat.length > 0 ? (
                <RenderMessage />
              ) : (
                <p
                  style={{
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    display: "flex",
                    fontSize: 25,
                  }}
                >
                  No Data Found.
                </p>
              )}
            </div>
          </Grid>
          {!state.closed ? (
            !ticketClose ? (
              <div
                style={{
                  display: "flex",
                  border: "1px solid #000",
                  alignItems: "center",
                  padding: "0 10px",
                  borderRadius: 5,
                  margin: "10px 32px 30px 15px",
                }}
              >
                <Input.TextArea
                  autoSize={{
                    minRows: 1,
                    maxRows: 3,
                  }}
                  autoFocus
                  maxLength={1000}
                  className="textarea"
                  style={{
                    border: 0,
                    outline: 0,
                    height: "90%",
                    width: "100%",
                    fontSize: 22,
                    maxHeight: 200,
                    height: "auto",
                    borderRadius: 0,
                    border: "0 #fff",
                    boxShadow: "none",
                  }}
                  placeholder="Write a message..."
                  value={message}
                  onChange={({ target }) =>
                    setMessage(target.value.trimStart())
                  }
                />
                <Send onClick={() => onSend(message)} />
              </div>
            ) : null
          ) : null}
        </Card>
      </Grid>
    </DashboardLayout>
  );
};

export default ChatScreen;
