import Method from "./method";

const apiFunctions = {
  loginApi: (payload) => Method.post("/admin/login", payload),

  logoutApi: () => Method.get("/admin/logout"),

  forgotPasswordApi: (payload) => Method.post("/admin/forgot", payload),

  changePasswordApi: (payload) => Method.post("/admin/changePassword", payload),

  resetPasswordLinkApi: (payload) => Method.post("/admin/forgotLink", payload),

  resetPasswordApi: (payload) => Method.post("/admin/resetPassword", payload),

  userListApi: (payload) => Method.post("/admin/userList", payload),

  userBlockApi: (payload) => Method.get(`/admin/blockUser/${payload.id}`),

  userDetailApi: (payload) => Method.get(`/admin/viewUser/${payload.id}`),

  dashboardApi: () => Method.get(`/admin/dashboard`),

  userDetailUpdateApi: (payload) => Method.put(`/admin/editUser`, payload),

  categoryListApi: (payload) => Method.post(`/category/list`, payload),

  addCategoryApi: (payload) => Method.post(`/category/add`, payload),

  editCategoryApi: (payload) => Method.put(`/category/edit`, payload),

  deleteCategoryApi: (payload) =>
    Method.delete(`/category/delete/${payload.id}`),

  needHelpListApi: (payload) => Method.post("/ticket/list", payload),

  quoteListApi: (payload) => Method.post(`/quote/list`, payload),

  addQuoteApi: (payload) => Method.post(`/quote/add`, payload),

  editQuoteApi: (payload) => Method.put(`/quote/edit`, payload),

  deleteQuoteApi: (payload) => Method.delete(`/quote/delete/${payload.id}`),
  chatLoad: (payload) => Method.post("ticket/chat", payload),
};

export default apiFunctions;
