import { combineReducers } from 'redux';
import { appState } from './appState'
import { authenticationReducer as authentication } from './authentication'
import { userManagementReducer as userManagement } from './userManagement'
import { categoryManagementReducer as categoryManagement } from './categoryManagement'
import { needHelpManagementReducer } from './needHelpManagement'
import { quoteManagementReducer as quoteManagement } from './quoteManagement'
const rootReducer = combineReducers({
  appState,
  authentication,
  userManagement,
  categoryManagement,
  needHelpManagementReducer,
  quoteManagement
})


export default rootReducer;