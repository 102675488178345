import React, { useState, useEffect } from "react";
import moment from "moment";
// Navigation
import { useHistory } from "react-router-dom";
// Styles
import "antd/dist/antd.css";
import "./dashboard.scss";
// antd
import { Table, Pagination } from "antd";
import { Card } from "react-bootstrap";
import { Grid, useTheme, useMediaQuery, Box } from "@material-ui/core";
// Custom components
import DashboardLayout from "../../layouts/dashboardLayout";
import {
  AppBar,
  Button,
  PaginationBar,
  Navbar,
  NavbarSearch,
} from "../../customComponents";
// Constants
import { appConstants } from "../../themes/constants";
// utils methods
import { useStyles } from "../../styles/styles";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { needHelpListInitiate } from "../../store/actions";
import { socket } from "../../utils/socket";
export const NeedHelp = () => {
  const styleProps = { searchable: true };
  // Hooks declarations
  const classes = useStyles(styleProps);
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const helpList = useSelector(
    (state) => state.needHelpManagementReducer.helpList
  );
  const pagination = useSelector((x) => x.needHelpManagementReducer.pagination);
  const [payload, setPayload] = useState({
    offset: 0,
    limit: 10,
    sort: "",
    order: 0,
    search: "",
  });
  const [currentPage, setCurrentPage] = useState(1);

  const matches = useMediaQuery(theme.breakpoints.down("xs"));
  useEffect(() => {
    document.title = appConstants.title;
    dispatch(needHelpListInitiate(payload));
  }, []);

  const handleChange = (pagination, filters, sorter) => {
    const tempPayload = {
      ...payload,
      sort: sorter?.columnKey,
      order:
        sorter.order === "ascend" ? 1 : sorter.order === "descend" ? -1 : null,
      offset: currentPage === 1 ? 0 : (currentPage - 1) * 10,
    };
    setPayload(tempPayload);
    dispatch(needHelpListInitiate(tempPayload));
  };

  const handlePagination = (pagination) => {
    setCurrentPage(pagination);
    const tempPayload = {
      ...payload,
      offset: pagination == 1 ? 0 : (pagination - 1) * 10,
    };
    setPayload(tempPayload);
    dispatch(needHelpListInitiate(tempPayload));
    window.scrollTo(0, 0);
  };

  const handleSearch = (e) => {
    if (!e.target.value.startsWith(" ")) {
      const tempPayload = { ...payload, search: e.target.value.trimLeft() };
      setPayload(tempPayload);
      dispatch(needHelpListInitiate(tempPayload));
    } else e.target.value = "";
  };
  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "sr",
      key: "sr",
      ellipsis: false,
      width: "90px",
      render: (key, data, index) => <>{payload.offset + index + 1}</>,
    },
    {
      title: "Ticket ID",
      dataIndex: "ticketId",
      key: "ticketId",
      sorter: false,
      ellipsis: false,
      width: "150px",
    },
    {
      title: "Title",
      dataIndex: "subject",
      key: "subject",
      sorter: true,
      ellipsis: true,
      width: "180px",
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: true,
      ellipsis: false,
      width: "280px",
      ellipsis: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      ellipsis: false,
      width: "180px",
      ellipsis: true,
      render: (key, data) => <>{data?.user?.name ?? "N/A"}</>,
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      ellipsis: false,
      width: "200px",
      render: (key, data) => (
        <>{moment(data?.createdAt).format("MMM DD, YYYY hh:mm A")}</>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: false,
      ellipsis: false,
      width: "150px",
      render: (key, data) => (
        <span style={{ color: data.closed && "red" }}>
          {data.closed ? "Closed" : "Pending"}
        </span>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "Action",
      width: "150px",
      align: "center",
      render: (key, data) => {
        return (
          <Grid className={classes.buttons}>
            <button
              className="table-action-button"
              onClick={() =>
                history.push({
                  pathname: "/chat-screen",
                  state: {
                    ticketId: data._id,
                    userId: data.user._id,
                    closed: data.closed,
                    id:data.ticketId
                  },
                })
              }
            >
              Action
            </button>
          </Grid>
        );
      },
    },
  ];

  return (
    <DashboardLayout>
      <AppBar breadcrumbs={[{ route: "/need-help", name: "Need Help" }]} />
      <Grid container className="mt-4">
        <Navbar
          title="Need Help"
          searchable
          onChange={handleSearch}
          value={payload.search}
        />
        <Card className={classes.card}>
          {matches && (
            <Box className={classes.searchBox}>
              <NavbarSearch />
            </Box>
          )}
          <Grid container>
            <Grid smUp={12} className={classes.tableContainerRow}>
              <Table
                className={classes.tableWrapper}
                scroll
                bordered
                columns={columns}
                dataSource={helpList}
                onChange={handleChange}
              />
              <Pagination
                current={currentPage}
                total={pagination?.totalCount ? pagination.totalCount : 10}
                className="table-pagination"
                onChange={handlePagination}
              />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </DashboardLayout>
  );
};
