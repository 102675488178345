import types from "../types/index";
const initialState = {
    isLoading: false,
    categoryList: [],
    pagination: {},
    categoryDetail: {},
};

export const categoryManagementReducer = (state = initialState, action) => {

    switch (action.type) {

        // category list
        case types.API_CATEGORY_LIST_INITIATE: return { ...state, isLoading: true };
        case types.API_CATEGORY_LIST_SUCCESS: return { ...state, isLoading: false, categoryList: action?.result?.data?.data, pagination: action.result.data.paginationData };

        // Success
        case types.API_CATEGORY_MANAGEMENT_SUCCESS: return { ...state, isLoading: false };

        //Category management Failed Case
        case types.API_CATEGORY_MANAGEMENT_FAILED: return { ...state, isLoading: false };

        //Default case
        default: return { ...state };
    }
};
